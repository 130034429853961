<template>
  <div>
    <div class="header">
      <div v-if="siteCont.id == 106" class="content" style="height: 117px;">
        <div class="left_menu">
          <img v-if="logo" :src="logo" alt="logo" class="logo" style="margin-top: 0px;" />
          <div v-else class="logo_box_bgm"></div>
          <ul class="nav" style="margin-top: 0px;" v-if="siteInfo.navigation">
            <li
              v-for="item in siteInfo.navigation"
              :key="item.name"
              @click="goNext(item)"
            >
              <!-- <a
                v-if="item.url.includes('http')"
                target="_blank"
                :href="item.url.trim()"
                >{{ item.name }}</a
              > -->
              <div
                v-if="item.url == menuIndex"
                ><a  class="active" > {{ item.name }}</a></div
              >
              <div v-else  active-class=""><a>{{ item.name }}</a></div>
            </li>
          </ul>
          <ul class="nav_box_bgm" v-else>
            <li v-for="item in 5" :key="item.name">
              <!--  -->
            </li>
          </ul>
        </div>
        <div class="right_login" style="margin-top: 0px;">
          <div class="serch">
            <input
              class="serch-input"
              type="text"
              placeholder="输入搜索的课程"
              v-model="indexSerch"
            />
            <div class="serch-btn" @click="indexSerchBtn"></div>
            <!-- <router-link :to="'/serch/'+indexSerch" class="serch-btn"></router-link> -->
          </div>
          <div
            v-if="userInfo && userInfo != ''"
            class="user-state"
            @mouseover="userOpeShow = true"
            @mouseleave="userOpeShow = false"
          >
            <img
              v-if="userInfo && userInfo.avatar != ''"
              :src="userInfo.avatar"
              class="user-head-portrait"
            />
            <img
              v-else
              :src="require('@/assets/head-default-personal.png')"
              class="user-head-portrait"
            />
            <span class="user-name">{{
              userInfo.nickname || userInfo.phone
            }}</span>
            <ul class="user-list" v-show="userOpeShow">
              <li class="option user-data">
                <router-link to="/userCenter/material">个人中心</router-link>
              </li>
              <li class="option learning-record">
                <router-link to="/userCenter/learning">学习记录</router-link>
              </li>
              <li class="option exit" @click="exitBtn">退出</li>
            </ul>
          </div>
          <div class="user-state" v-else>
            <span class="login-btn" @click="headerLoginBtn">登录</span>
            <template v-if="reginState">
              <router-link style="padding: 8px 13px;border-radius: 5px;" class="reg-btn" to="/welcomeRegistered"
              >立即注册</router-link
            >
            </template>
          </div>
        </div>
      </div>
      <div v-else class="content">
        <div class="left_menu">
          <img v-if="logo" :src="logo" alt="logo" class="logo" />
          <div v-else class="logo_box_bgm"></div>
          <ul class="nav" v-if="siteInfo.navigation">
            <li
              v-for="item in siteInfo.navigation"
              :key="item.name"
              @click="goNext(item)"
            >
              <!-- <a
                v-if="item.url.includes('http')"
                target="_blank"
                :href="item.url.trim()"
                >{{ item.name }}</a
              > -->
              <div
                v-if="item.url == menuIndex"
                ><a  class="active" > {{ item.name }}</a></div
              >
              <div v-else  active-class=""><a>{{ item.name }}</a></div>
            </li>
          </ul>
          <ul class="nav_box_bgm" v-else>
            <li v-for="item in 5" :key="item.name">
              <!--  -->
            </li>
          </ul>
        </div>
        <div class="right_login">
          <div class="serch">
            <input
              class="serch-input"
              type="text"
              placeholder="输入搜索的课程"
              v-model="indexSerch"
            />
            <div class="serch-btn" @click="indexSerchBtn"></div>
            <!-- <router-link :to="'/serch/'+indexSerch" class="serch-btn"></router-link> -->
          </div>
          <div
            v-if="userInfo && userInfo != ''"
            class="user-state"
            @mouseover="userOpeShow = true"
            @mouseleave="userOpeShow = false"
          >
            <img
              v-if="userInfo && userInfo.avatar != ''"
              :src="userInfo.avatar"
              class="user-head-portrait"
            />
            <img
              v-else
              :src="require('@/assets/head-default-personal.png')"
              class="user-head-portrait"
            />
            <span class="user-name">{{
              userInfo.nickname || userInfo.phone
            }}</span>
            <ul class="user-list" v-show="userOpeShow">
              <li class="option user-data">
                <router-link to="/userCenter/material">个人中心</router-link>
              </li>
              <li class="option learning-record">
                <router-link to="/userCenter/learning">学习记录</router-link>
              </li>
              <li class="option exit" @click="exitBtn">退出</li>
            </ul>
          </div>
          <div class="user-state" v-else>
            <span class="login-btn" @click="headerLoginBtn">登录</span>
            <template v-if="reginState">
              <router-link  class="reg-btn" to="/welcomeRegistered"
              >注册</router-link
            >
            </template>
          </div>
        </div>
      </div>
    </div>
    <Tip
      :text="tipModal.text"
      :ico="tipModal.ico"
      :showBtns="tipModal.showBtns"
      v-if="tipModal.show"
      :fun="tipModal.fun"
      :confirmFun="tipModal.confirmFun"
      :confirmBtnText="tipModal.confirmBtnText"
      :cancelFun="tipModal.cancelFun"
      :cancelBtnText="tipModal.cancelBtnText"
    />
    <Login v-if="loginModal.show" />

    <div class="mask_box" v-if="formState">
      <div class="form_box">
        <p class="close" @click="formState = false">
          <img src="@/assets/mask_box/close1.png" alt="" />
        </p>
        <p class="big_title">单位管理后台</p>
        <p class="little_title">使用讲解演示预约</p>
        <div class="line"></div>
        <div class="tips">
          <img src="@/assets/mask_box/tips.png" alt="" /><span
            >如何设置培训和考试计划？<br />
            如何查看和导出学员学习数据？ <br />
            一键预约<br />
            专属老师在线解答<br />
            手把手带你了解管理后台的使用小妙招！</span
          >
        </div>
        <div class="form_input">
          <ul>
            <li>
              <label for="">姓 &nbsp;&nbsp; 名：</label>
              <input
                v-model="formData.name"
                type="text"
                class="form_name"
                placeholder="请输入姓名"
              />
            </li>
            <li>
              <label for="">手机号：</label>
              <input
                v-model="formData.phone"
                type="text"
                class="form_phone"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </li>
            <li>
              <label for="">你想了解哪部分功能？（选填）：</label>
              <input
                v-model="formData.title"
                type="text"
                class="form_text"
                placeholder="请输入"
              />
            </li>
          </ul>
          <p class="btn" @click="submitBtn()">确认提交</p>
        </div>
      </div>
    </div>
    <div class="plan_box" v-if="planState">
      <div class="form_box">
        <div class="banner_box">
          <p class="close" @click="planState = false">
            <img src="@/assets/mask_box/close2.png" alt="" />
          </p>
          <img src="@/assets/mask_box/banner.png" alt="" />
        </div>
        <div class="title">年度培训活动如何创新</div>
        <p class="little_title">
          劳模事迹宣讲、女职工活动、劳动和技能竞赛、疗休养、年度职工评选、职工网络春晚……
          工会活动年年做，是时候来点创新了！<br />
          一键预约专属活动管家，为您介绍年度活动合集，手把手教您如何让职工参与更积极！
        </p>
        <div class="form_input">
          <ul>
            <li>
              <label for="">姓 &nbsp;&nbsp; 名：</label>
              <input
                v-model="planData.name"
                class="plan_name"
                type="text"
                placeholder="请输入姓名"
              />
            </li>
            <li>
              <label for="">手机号：</label>
              <input
                v-model="planData.phone"
                class="plan_phone"
                type="text"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </li>
          </ul>
          <p class="btn" @click="planSubmit()">确认提交</p>
        </div>
      </div>
    </div>
    <div
      class="fixed_right"
      :style="'top:' + rightBoxTop"
       v-if="(siteCont && siteCont.waiter_mobile&&siteCont.waiter_name) || (siteInfo.site_info&&siteInfo.site_info.id == 49)"
    >
      <ul>
        <li class="show_more"  v-if="siteCont && siteCont.waiter_mobile&&siteCont.waiter_name">
          <p class="img">
            <img src="@/assets/mask_box/right_icon1.png" alt="" />
          </p>
          <p class="text">联系我们</p>
          <div class="show_text">
            <div class="title_icon">
              <img src="@/assets/mask_box/right_icon_show.png" alt="" />
              <span>联系我们</span>
            </div>
            <div class="phone_list flex">
              <div class="left_text">
                <div class="num"><span>联系老师</span>{{siteCont.waiter_name}}</div>
                <div class="num" v-if="!siteCont.waiter_mobile.includes('|')"><span>联系电话</span>{{siteCont.waiter_mobile}}</div>
                <div class="num num2" v-else>
                  <span>联系电话</span>
                 <div>
                   <p v-for="item in siteCont.waiter_mobile.split('|')" :key="item">{{item}}</p>
                 </div>
                </div>
                <!-- <div class="num"><span>培训咨询</span>{{siteCont.waiter_mobile}}</div> -->
                <!-- <div class="num"><span>商务合作</span>17610066752</div> -->
              </div>
              <div class="code_img">
                <!-- <img src="@/assets/mask_box/code_img.jpg" alt="" /> -->
                <img v-if="siteCont.waiter_qrcode" :src="siteCont.waiter_qrcode" alt="" />
              </div>
            </div>
          </div>
        </li>
        <li class="line"  v-if="siteCont && siteCont.waiter_mobile&&siteCont.waiter_name  && (siteInfo.site_info&&siteInfo.site_info.id == 49)"></li>
        <li @click="formState = true" v-if="siteInfo && (siteInfo.site_info&&siteInfo.site_info.id == 49)">
          <p class="img">
            <img src="@/assets/mask_box/right_icon2.png" alt="" />
          </p>
          <p class="text">培训计划设置<br />功能演示预约</p>
        </li>
        <li class="line"  v-if="siteCont && siteCont.waiter_mobile&&siteCont.waiter_name  && (siteInfo.site_info&&siteInfo.site_info.id == 106)"></li>
        <li @click="goNewUrl('https://w.wjx.com/vm/hWF7cGc.aspx#')" v-if="siteInfo && (siteInfo.site_info&&siteInfo.site_info.id == 106)">
          <p class="img">
            <img src="@/assets/mask_box/recruit.png" alt="" />
          </p>
          <p class="text">讲师招募</p>
        </li>
        <li class="line"  v-if="siteCont && siteCont.waiter_mobile&&siteCont.waiter_name  && (siteInfo.site_info&&siteInfo.site_info.id == 106)"></li>
        <li @click="goNewUrl('https://oss.images.kskstudy.com/uploads/20240717/dbd425788216a352812d0d11652b077f.pdf')" v-if="siteInfo && (siteInfo.site_info&&siteInfo.site_info.id == 106)">
          <p class="img">
            <img src="@/assets/mask_box/student.png" alt="" />
          </p>
          <p class="text">学员操作步骤</p>
        </li>
        <li class="line"  v-if="siteCont && siteCont.waiter_mobile&&siteCont.waiter_name  && (siteInfo.site_info&&siteInfo.site_info.id == 106)"></li>
        <li @click="goNewUrl('https://oss.images.kskstudy.com/uploads/20240717/4cccf5f3915b42aedcd4fb04e5a9bf53.pdf')" v-if="siteInfo && (siteInfo.site_info&&siteInfo.site_info.id == 106)">
          <p class="img">
            <img src="@/assets/mask_box/enterprise.png" alt="" />
          </p>
          <p class="text">企业操作步骤</p>
        </li>
      </ul>
    </div>
    <!-- <div
      class="fixed_right"
      :style="'top:' + rightBoxTop"
      v-if="siteInfo && siteInfo.site_info.id == 82"
    >
      <ul>
        <li class="show_more">
          <p class="img">
            <img src="@/assets/mask_box/right_icon1.png" alt="" />
          </p>
          <p class="text">咨询服务</p>
          <div class="show_text">
            <div class="title_icon">
              <img src="@/assets/mask_box/right_icon_show.png" alt="" />
              <span>咨询服务</span>
            </div>
            <div class="phone_list flex">
              <div class="left_text">
                <div class="num"><span>咨询老师</span>黄老师</div>
                <div class="num"><span>咨询电话</span>13758919555</div>
                <div class="num">
                  <span style="margin-left: 58px"></span> 0579-81552301
                </div>
              </div>
              <div class="code_img">
                <img src="@/assets/mask_box/code_82.jpg" alt="" />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div> -->
    <div
      class="fixed_left"
      v-if="leftBoxState && siteInfo && (siteInfo.site_info&&siteInfo.site_info.id == 49)"
    >
      <p class="tips">培训活动如何创新</p>
      <p class="btn" @click="planState = true">点击<br />了解</p>
    </div>

    <!-- 劳动关系学院-领取证书弹框 -->
    <div class="ldxy_mask_box mask_box" v-if="cerMaskState">
      <div class="cont_data" v-if="cerShowIndex==1">
        <div class="head_title">
          <p class="text">领取培训证书</p>
          <p class="close" @click="closeCerMask()"><img src="@/assets/pc/pop/close_icon.png" alt=""></p>
        </div>
        <div class="cont_info">
          <div class="learn_time">
            <p class="text">当前学习总学时：</p>
            <p class="num">
              {{studyData.user_study_credit_hours}}<span>/{{studyData.total_credit_hours}}</span>
            </p>
          </div>
          
          <div class="tips_text state2" v-if="studyData.isGetCert==1"><span>您已领取过证书</span></div>
          <template  v-if="studyData.isGetCert==0">
            <div class="tips_text state2" v-if="studyData.isCanGetCert==0"><span>不满足领取证书条件，满足总学时要求后方可领取</span></div>
            <div class="tips_text state1" v-if="studyData.isCanGetCert==1"><span>满足领取证书条件</span></div>
          </template>

        </div>
        <div class="get_btn cursor disable" v-if="studyData.isGetCert==1 || studyData.isCanGetCert==0">领取证书</div>
        <div class="get_btn cursor" v-else @click="getBookInfo()">领取证书</div>
      </div>

      <div class="msg_cont_info" v-if="cerShowIndex==2">
        <p class="close" @click="closeCerMask()"><img src="@/assets/pc/pop/close_icon.png" alt=""></p>
        <div class="true_icon"><img src="@/assets/pc/pop/true.png" alt=""></div>
        <div class="text_info">
          <p class="text">您已完成教学评价与入驻申请</p>
          <p class="text">证书将<span>发送至您的邮箱</span></p>
        </div>
        <div class="btn_sure" @click="closeCerMask()">确定</div>
      </div>
      <div class="question_cont_box" v-if="cerShowIndex==3">
        <div class="head_title">
          <p class="text">评估问卷</p>
          <p class="close" @click="closeCerMask()"><img src="@/assets/pc/pop/close_icon.png" alt=""></p>
        </div>
        <div class="quest_cont_info">
          <div class="intro_text_info">
            <p>项目评估问卷 只可填写一次，感谢您的支持!</p>
          </div>
          <ul>
            <div class="tips_text">您对本次项目的总体评价</div>
            <div class="li_item">
              <li>
                <p class="title_text"><span>*</span>项目设计目标明确，立足当前，讲求实用，坚持需求导向，具有先进性，可持续性。</p>
                <p class="input"><input type="text" @blur="numInput(0)" v-model.number="questForm[0].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>教学目标明确，课程设计科学，师资配置合理，能够因材施教。</p>
                <p class="input"><input type="text" @blur="numInput(1)" v-model.number="questForm[1].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <!-- <li class="w_100">
                <p class="title_text"><span>*</span>班级管理有序，纪律严明，教学环境设施满足教学要求;及时开展课程评估，注重过程监督和反馈。</p>
                <p class="input"><input type="text" @blur="numInput(2)" v-model.number="questForm[2].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>工作人员履职尽责，严格遵守工作纪律和师德师风规范，服务意识强。</p>
                <p class="input"><input type="text" @blur="numInput(3)" v-model.number="questForm[3].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>对培训项目整体开展情况的总评价。</p>
                <p class="input"><input type="text" @blur="numInput(4)" v-model.number="questForm[4].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li> -->
            </div>
            <div class="tips_text">您对听课的总体评价</div>
            <div class="li_item">
              <li>
                <p class="title_text"><span>*</span>政治观点正确，态度端正、仪表得体、关爱学生，体现立德树人、为党育人，严格遵守师德师风规范。</p>
                <p class="input"><input type="text" @blur="numInput(5)" v-model.number="questForm[5].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>目标明确具体，体现知识传授，能力与素质培育，注重实践应用，针对性强，教材选用规范。</p>
                <p class="input"><input type="text" @blur="numInput(6)" v-model.number="questForm[6].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>内容充实精准，深度广度适中，重点难点突出基础性、应用性和先进性统一，体现课程思政设计。</p>
                <p class="input"><input type="text" @blur="numInput(7)" v-model.number="questForm[7].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>教师精神饱满，课堂气氛活跃，达成教学目标、重点难点问题有效解决。</p>
                <p class="input"><input type="text" @blur="numInput(8)" v-model.number="questForm[8].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
              <li>
                <p class="title_text"><span>*</span>教学方法选用适合学生特点，教学手段多样化，注重问题牵引，有效开展互动。</p>
                <p class="input"><input type="text" @blur="numInput(9)" v-model.number="questForm[9].answer" min="1" max="5" pattern=[0-9]* placeholder="限填1-5分" ></p>
              </li>
            </div>
            <div class="tips_text">以下对“新时代大力弘扬三种精神的重要意义与实践路径”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(10)" v-model.number="questForm[10].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“新质生产力下的人才创新能力培养“课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(11)" v-model.number="questForm[11].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“劳模和工匠人才创新工作室建设与实践”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(12)" v-model.number="questForm[12].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“劳模工匠个人成长与创新团队建设”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(13)" v-model.number="questForm[13].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“高质量发展形势下的班组建设“课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(16)" v-model.number="questForm[16].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“劳模工匠胜任能力与成长规律”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(17)" v-model.number="questForm[17].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“岗位创新成果及成果申报”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(18)" v-model.number="questForm[18].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“劳模工匠创新思维培养与训练”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(24)" v-model.number="questForm[24].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“创新工作室场景与实践”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(25)" v-model.number="questForm[25].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <div class="tips_text">以下对“领衔人分享沙龙”课程评估</div>
            <li>
              <p class="title_text"><span>*</span>对本课程的总体评价。</p>
              <p class="input"><input type="text" @blur="numInput(26)" v-model.number="questForm[26].answer" min="1" max="10" pattern=[0-9]* placeholder="限填1-10分" ></p>
            </li>
            <li>
              <p class="title_text"><span>*</span>真实姓名</p>
              <p class="input">
                <input  v-model="questForm[23].answer"  placeholder="真实姓名（证书上使用，请务必真实）" >
              </p>
            </li>
            <li>
              <p class="title_text"><span>*</span>接收证书邮箱</p>
              <p class="input">
                <input  v-model="questForm[15].answer"  placeholder="请输入接收证书邮箱" >
              </p>
            </li>
            <li>
              <p class="title_text"><span></span>是否有下一步研修意向</p>
              <p class="input">
               <el-select v-model="questForm[19].answer" class="custom-placeholder">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
               </el-select>
              </p>
            </li>
            <template v-if="questForm[19].answer=='是'">
              <li>
                <p class="title_text"><span>*</span>研修形式</p>
                <p class="input">
                <el-select v-model="questForm[20].answer" placeholder="请选择研修形式" class="custom-placeholder">
                  <el-option label="线上" value="线上"></el-option>
                  <el-option label="线下" value="线下"></el-option>
                  <el-option label="线上线下相结合" value="线上线下相结合"></el-option>
                </el-select>
                </p>
              </li>
              <li>
                <p class="title_text"><span>*</span>研修内容</p>
                <p class="input">
                <el-select v-model="selectInfo" placeholder="请选择研修内容" class="custom-placeholder">
                  <el-option label="知识技能类" value="知识技能类"></el-option>
                  <el-option label="时政前沿类" value="时政前沿类"></el-option>
                  <el-option label="创新创效类" value="创新创效类"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
                </p>
                <p class="input" v-if="selectInfo=='其他'">
                  <input  v-model="questForm[21].answer"  placeholder="请输入研修内容" >
                </p>
              </li>
            </template>
            <li>
              <p class="title_text"><span>*</span>研修意向</p>
              <p class="textarea">
                <textarea name=""  v-model="questForm[22].answer" id="" placeholder="请输入"></textarea>
              </p>
            </li>
            <li>
              <p class="title_text">如果您对此培训的组织管理，课程设置，教学内容，教师教学，工作服务等方面有任何宝贵意见和建议，请告诉我们。</p>
              <p class="textarea">
                <textarea name=""  v-model="questForm[14].answer" id="" placeholder="请输入"></textarea>
              </p>
            </li>
          </ul>
          <div class="submit_btn" @click="questSubmit()">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tip from "@/components/pc/tip.vue";
import Login from "@/components/pc/login.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      loginTip: "", //登录提示
      indexSerch: "",
      userOpeShow: false, //昵称下拉显示隐藏
      menuIndex: "/index",
      planState: false,
      formState: false,
      formData: {
        name: "",
        phone: "",
        title: "",
        type: 1,
        source_url: "",
      },
      planData: {
        name: "",
        phone: "",
        type: 2,
        source_url: "",
      },
      leftBoxState: false,
      rightBoxTop: "50%",
      siteCont:'',
      reginState:true,

      cerMaskState:false,//领取证书弹框sate
      cerShowIndex:1,
      questForm:[
        {id:1,answer:''},  //项目设计目标明确，立足当前，讲求实用，坚持需求导向，具有先进性，可持续性。
        {id:2,answer:''},  //教学目标明确，课程设计科学，师资配置合理，能够因材施教。
        {id:3,answer:1},   //已去掉 班级管理有序，纪律严明，教学环境设施满足教学要求
        {id:4,answer:1},   //已去掉 工作人员履职尽责，严格遵守工作纪律和师德师风规范，服务意识强。
        {id:5,answer:1},   //已去掉 对培训项目整体开展情况的总评价。
        {id:6,answer:''},  //政治观点正确，态度端正、仪表得体、关爱学生，体现立德树人、为党育人，严格遵守师德师风规范。
        {id:7,answer:''},  //目标明确具体，体现知识传授，能力与素质培育，注重实践应用，针对性强，教材选用规范。
        {id:8,answer:''},  //内容充实精准，深度广度适中，重点难点突出基础性、应用性和先进性统一，体现课程思政设计。
        {id:9,answer:''},  //教师精神饱满，课堂气氛活跃，达成教学目标、重点难点问题有效解决。
        {id:10,answer:''}, //教学方法选用适合学生特点，教学手段多样化，注重问题牵引，有效开展互动。
        {id:11,answer:''}, //以下对“劳模和工匠人才创新工作室建设和实践”课程评估
        {id:12,answer:''}, //以下对“新时代大力弘扬三种精神的重要意义与实践路径“课程评估
        {id:13,answer:''}, //以下对“劳模工匠人才个人成长与创新团队建设”课程评估
        {id:14,answer:''}, //以下对“劳模工匠胜任能力与成长规律”课程评估
        {id:15,answer:''}, //建议 如果您对此培训的组织管理，课程设置，教学内容，教师教学，工作服务等方面有任何宝贵意见和建议，请告诉我们。
        {id:16,answer:''}, //邮箱 接收证书邮箱
        {id:17,answer:''}, //以下对“创新思维与创新方法“课程评估
        {id:18,answer:''}, //以下对“创新工作室场景与实践”课程评估
        {id:19,answer:''}, //以下对“领衔人分享沙龙”课程评估
        {id:20,answer:'是'}, //是否有下一步研修意向
        {id:21,answer:''}, //研修形式
        {id:22,answer:''}, //其他
        {id:23,answer:''}, //研究意向
        {id:24,answer:''}, //姓名
        {id:25,answer:''}, 
        {id:26,answer:''}, 
        {id:27,answer:''}, 
      ],
      selectInfo:'',
      studyData:{},
    };
  },
  props: ["siteInfo", "logo"],
  components: {
    Tip,
    Login,
  },
  watch:{
    $route:{
      handler(newVal){
        if(newVal.path=='/'){
          this.menuIndex = '/index';  
        }else{
          this.menuIndex = newVal.redirectedFrom || newVal.path;
        }
      },
      immediate:true,
    },
  },
  mounted() {
    if(window.location.host=='cxgzspx.kskstudy.com' || 
      window.location.host=='ymjg.kskstudy.com' || 
      window.location.host=='culrcx.kskstudy.com' ||
      window.location.host=='bqbzz.kskstudy.com'
    ){
      this.reginState = false
    }else{
      this.reginState = true
    }
    this.GetUserInfo();
    let names = localStorage.getItem("menuName");
    if (names) {
      // this.menuIndex = names;
    }
  // 从中国劳动关系学院跳转过来的免登录功能begin
    let urlDataInfo = this.$route.query
    if(urlDataInfo.token && urlDataInfo.url){
      this.autoLoginForLdgx()
      // if(!this.userInfo){
      //   // 未登录的情况下
      //   this.autoLoginForLdgx()
      // }else{
      //   // 已登录的情况下
      //   if(this.userInfo.phone==urlDataInfo.phone){
      //     window.location.href = urlDataInfo.url
      //   }else{
      //     this.autoLoginForLdgx()
      //   }
      // }
    }
  // 从中国劳动关系学院跳转过来的免登录功能end
    let urlQuery = this.$route.query;
    //判断
    if (urlQuery.token && urlQuery.phone && urlQuery.nickname) {
      if (
        !this.userInfo ||
        this.userInfo.phone !=
          new Buffer(urlQuery.phone, "base64").toString("utf8")
      ) {
        let data = {
          token: urlQuery.token,
          phone: urlQuery.phone,
          nickname: urlQuery.nickname,
        };
        // 'js.1659434272.xVJKdFCcLM'
        // data = {
        //   token: 'js.1659434272.86f152a3f0781ece99a32b74d1468560',
        //   phone: 'MTU4MTAyMjEwOTM=',
        //   nickname: 'Y2hvbmc=',
        // }
        // var decryptStr = new Buffer(urlQuery.nickname, 'base64').toString('utf8')
        this.$axios.post(`/v1/login/autoLogin`, data).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.GetUserInfo();
            this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }); //登录记录
            if (urlQuery.type == 2) {
              this.$router.push("/userCenter/learning");
            }
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
      }
    }
    if (this.$route.query.authCode) {
      this.logBtnGrjy()
    }
    // if (JSON.parse(localStorage.getItem('auth')) === null) {
    //   if (this.userInfo.id == 107) {
    //     console.log(this.siteinfoId,'res.data.site_info');
    //     if (this.$route.query.authCode) {
    //       this.logBtnGrjy()
    //       console.log('跳转登录')
    //     }
    //   }
    // }
    window.addEventListener("scroll", this.handleScroll);
    if(localStorage.getItem('siteInfo')){
      this.siteCont = JSON.parse(localStorage.getItem('siteInfo'))
    }
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
      "ShowTipModal",
    ]), //登录弹窗
    goNewUrl(url) {
      window.open(url);
    },
    // 
    logBtnGrjy() {
      console.log('qhAutoLogin');
      this.$axios.post(`/v1/qhAutoLogin`, { authCode: this.$route.query.authCode }).then(res => {
        if (res.code == 0) {
          localStorage.setItem("auth", JSON.stringify(res.data.auth));
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("userInfo", JSON.stringify(res.data.user));
          this.GetUserInfo();
          this.$router.push('/')
          window.location.reload()
          this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }).then(() => {
            // this.$router.replace('/')
          });
        } else {
            this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 劳动关系学院路由-获取证书点击
    routerBtn(item) {
      if(localStorage.getItem("auth")){
        this.$axios.post(`/v1/course/studyTotalDuration`,{},{useLog:true}).then(res=>{
          console.log(res)
          this.studyData = res.data
          this.cerMaskState = true
          this.cerShowIndex=1
        })
      }else{
        this.ShowLoginModal()
      }
      
    },

    // 领取证书
    getBookInfo(){
      this.cerShowIndex=3
    },

    // 提交获取证书的表单
    questSubmit(){
      for(let i=0;i<this.questForm.length;i++){
        if(i <14  &&  i!=2 &&i!=3&&i!=4 && this.questForm[i].answer==''){
          return this.$message.error('请按要求输入对应分值后提交')
        }
        if(( i==16 || 1==17 || i==18) && this.questForm[i].answer==''){
          return this.$message.error('请按要求输入对应分值后提交')
        }
      }
      var re = /^\w+@[a-z0-9]+\.[a-z]{2,4}$/;
      if(!re.test(this.questForm[15].answer)){
        return this.$message.error('请输入正确的邮箱地址')
      }
      if(this.selectInfo!='其他'){
        this.questForm[21].answer = this.selectInfo
      }else{
        this.questForm[21].answer = this.selectInfo + this.questForm[21].answer
      }
      if(this.questForm[19].answer=='是'){
        if(this.questForm[20].answer==''){
          return this.$message.error('请输入研修形式')
        }
        if(this.questForm[21].answer==''){
          return this.$message.error('请输入研修内容')
        }
      }else{
          this.questForm[20].answer=''
          this.questForm[21].answer=''
        }
      if(this.questForm[22].answer==''){
         return this.$message.error('请输入研修意向')
      }
      if(this.questForm[23].answer==''){
         return this.$message.error('请输入真实姓名')
      }
      this.$axios.post(`/v1/course/submitCertForm`,this.questForm,{useLog:true}).then(res=>{
        console.log(res)
        if(res.code == 0){
          this.cerShowIndex=2
        }else{
            this.$message.error(res.msg)
          }
      })
    },

    // 关闭领取证书弹框
    closeCerMask(){
      this.cerMaskState = false
      this.cerShowIndex=1
      for(let i=0;i<this.questForm.length;i++){
        this.questForm[i].answer=''
      }
    },
    numInput(num){
      console.log(num,'num====')
      if(typeof this.questForm[num].answer === 'number'){
        if(Number(this.questForm[num].answer)<1 && Number(this.questForm[num].answer)!=''){
          this.questForm[num].answer= 1
        }
        if(num>9){
          if(Number(this.questForm[num].answer)>10){
            this.questForm[num].answer= 10
          }
        }else{
          if(Number(this.questForm[num].answer)>5){
            this.questForm[num].answer= 5
          }
        }
        
      }else{
        this.questForm[num].answer=this.questForm[num].answer.replace(/\D/g,'')
      }
     
    },


    autoLoginForLdgx(){
      let urlDataInfo = this.$route.query
      let data = {
          token: urlDataInfo.token,
        };
        this.$axios.post(`/v1/login/autoLoginForLdgx`, data).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.GetUserInfo();
            window.location.href = urlDataInfo.url
            this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }); //登录记录
            // if (urlQuery.type == 2) {
            //   this.$router.push("/userCenter/learning");
            // }
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
    },

    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 200) {
        //如果达到某个值
        this.leftBoxState = true;
        this.rightBoxTop = "20%";
        // $('.fixed_left').fadeIn()
      } else {
        this.rightBoxTop = "70%";
        // $('.fixed_right').css('top','70%')
        this.leftBoxState = false;
      }
    },

    //header登录按钮
    headerLoginBtn() {
      this.ShowLoginModal(); //登录弹窗
    },
    goNext(data) {
     
      if(data.url.includes('http')){
        window.open(data.url)
      }
      if(data.url=='/getCert'){
        this.menuIndex = '/getCert'
        if(!localStorage.getItem("auth")){
          this.ShowLoginModal()
          return
        }else{
          this.routerBtn()
        }
      }
       if(data.url=='/userCenter'){
        if(!localStorage.getItem("auth")){
          this.ShowLoginModal()
          return
        }
      }

      
      let set_info = JSON.parse(localStorage.getItem("siteInfo"));
      document.getElementById("titleName").innerHTML =
        data.name + " - " + set_info.name;
      // this.menuIndex = data.url;
      localStorage.setItem("menuName", data.name);
      this.$router.push(data.url)
    },
    //退出按钮
    exitBtn() {
      localStorage.removeItem("user");
      localStorage.removeItem("auth");
      localStorage.removeItem("userInfo");
      this.ClearUserInfo();

      if (this.$route.path.includes("userCenter")) {
         localStorage.setItem("menuName", '首页');
         this.menuIndex = '首页'
        this.$router.push("/index");
         
      } else {
        this.$router.go(0);
      }
    },
    //搜索
    indexSerchBtn() {
      let _this = this;
      this.$router.push({ name: "Serch", params: { text: _this.indexSerch } });
    },
    submitBtn() {
      this.formData.source_url = window.location.origin;
      this.$axios.post(`/v1/subcribe`, this.formData).then((res) => {
        if (res.code == 0) {
          this.formState = false;
          this.ShowTipModal({
            text: "提交成功",
          });
        }
      });
    },
    planSubmit() {
      this.planData.source_url = window.location.origin;
      this.$axios.post(`/v1/subcribe`, this.planData).then((res) => {
        if (res.code == 0) {
          this.planState = false;
          this.ShowTipModal({
            text: "提交成功",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  .content {
    width: 1200px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_menu {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .right_login {
      display: flex;
      align-items: center;
      margin-top: 37px;
    }
  }
  .logo {
    width: 180px;
    height: 72px;
    // float: left;
    margin: 20px 65px 0 0;
  }
  .logo_box_bgm {
    width: 180px;
    height: 72px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin: 20px 65px 0 0;
  }
}
.nav {
  overflow: hidden;
  // float: left;
  margin-top: 30px;
  display: flex;
  li {
    // float: left;
    margin-right: 24px;
    a {
      display: inline-block;
      font-size: 16px;
      color: #000000;
      white-space: nowrap;
      padding-bottom: 6px;
      position: relative;
      cursor: pointer;
      &.active {
        font-family: PingFangSC-Heavy, PingFang SC;
        color: #254ed4;
      }
      &.active::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        color: #254ed4;
        background-color: #254ed4;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
.nav_box_bgm {
  margin-top: 30px;
  display: flex;
  li {
    width: 80px;
    margin-right: 20px;
    height: 27px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
}
.serch {
  width: 256px;
  height: 34px;
  background: #ffffff;
  border-radius: 17px;
  border: 1px solid #e5e5e5;
  // float: right;
  // margin: 27px 34px 0 0;
  position: relative;
  margin-right: 34px;
  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 12px;
    background: #ccc;
    border-radius: 1px;
    position: absolute;
    top: 11px;
    right: 40px;
  }
  .serch-input {
    width: 195px;
    height: 34px;
    margin-left: 20px;
  }
  .serch-btn {
    width: 20px;
    height: 20px;
    background: url(~@/assets/search-grey-ico.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
    right: 12px;
    top: 7px;
    cursor: pointer;
  }
}
.user-state {
  float: right;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .login-btn {
    color: #000;
    cursor: pointer;
    margin-right: 14px;
    white-space: nowrap;
  }
  .reg-btn {
    display: inline-block;
    padding: 7px 17px;
    border-radius: 2px;
    background: #254ed4;
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
  }
  .user-head-portrait {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-list {
    position: absolute;
    padding: 20px 16px 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
    right: 0;
    top: 30px;
    z-index: 99;
    .option {
      padding-left: 26px;
      height: 26px;
      line-height: 26px;
      font-size: 12px;
      color: #666;
      white-space: nowrap;
      cursor: pointer;
      a {
        font-size: 12px;
        color: #666;
      }
      &.user-data {
        background: url(~@/assets/user-ico2.png) no-repeat;
        background-size: 24px;
        margin-bottom: 12px;
      }
      &.learning-record {
        background: url(~@/assets/edit-ico.png) no-repeat;
        background-size: 24px;
        margin-bottom: 16px;
      }
      &.exit {
        border-top: 1px solid #e5e5e5;
        background: url(~@/assets/exit-ico.png) no-repeat 0 center;
        background-size: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
}
.log-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  .content {
    width: 420px;
    background-color: #fff;
    border-radius: 8px;
  }
  .top {
    width: 100%;
    height: 200px;
    background: url(~@/assets/log-modal-top-bg.png) no-repeat;
    background-size: 100% auto;
    position: relative;
    .close-log {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: -50px;
      top: 0;
      cursor: pointer;
    }
    .tab-titles {
      width: 100%;
      padding-bottom: 10px;
      position: absolute;
      bottom: 0;
      z-index: 0;
    }
    .tab-title {
      font-size: 18px;
      color: #fff;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
      margin: 0 17px;
      position: relative;
      cursor: pointer;
      &.active {
        font-weight: 900;
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: 6px solid #fff;
          position: absolute;
          bottom: -20px;
          left: 30px;
          transform: rotate(45deg);
        }
      }
    }
  }
  .tab-content {
    box-sizing: border-box;
    width: 100%;
    height: 368px;
    padding: 34px 34px 30px;
    position: relative;
  }
  .input-box {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    padding-left: 60px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      background-color: #d8d8d8;
      position: absolute;
      left: 45px;
      top: 15px;
    }
    &.user-name {
      padding-top: 0;
      margin-bottom: 46px;
      background: url(~@/assets/phone-grey-ico.png) no-repeat 14px center;
      background-size: auto 21px;
    }
    &.password {
      background: url(~@/assets/lock-open-ico.png) no-repeat 14px center;
      background-size: auto 22px;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      height: 42px;
      line-height: 42px;
    }
  }
  .tip {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px 0 34px;
    font-size: 12px;
    color: #cd1b14;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 90px;
    left: 0;
  }
  .ope {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .avoid-login,
    a {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #666;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
      //禁用双击选中
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      cursor: pointer;
    }
    .radio {
      margin-right: 10px;
    }
  }
  .reg-btn,
  .log-btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    letter-spacing: 2px;
    margin-bottom: 14px;
    cursor: pointer;
  }
  .log-btn {
    background-color: #254ed4;
    color: #fff;
  }
  .reg-btn {
    background: #ffffff;
    border-radius: 6px;
    color: #333;
  }
  .agreement {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
    input {
      margin-right: 4px;
      cursor: pointer;
    }
    .agreement-link {
      color: #254ed4;
    }
  }
}
/* 弹框 */
.mask_box,
.plan_box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  // display: none;
}

/* .plan_box{
    display: block;
} */
.form_box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 592px;
  height: 580px;
  background: #ffffff;
  box-shadow: 0px 9px 20px 4px rgba(20, 6, 5, 0.16);
  border-radius: 4px;
}

.form_box .close {
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px 0;
}

.form_box .close img {
  width: 30px;
  height: 30px;
}

.big_title,
.little_title {
  text-align: center;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #222222;
}

.form_box .little_title {
  margin-top: 9px;
  font-size: 22px;
}

div.line {
  width: 592px;
  height: 1px;
  background: #d0d0d0;
  margin: 29px 0 16px;
}

div.tips {
  margin-left: 33px;
  display: flex;
  /* align-items: center; */
}

div.tips img {
  width: 16px;
  height: 20px;
  margin-top: 6px;
}

div.tips span {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999;
  margin-left: 7px;
  line-height: 30px;
}

.form_input ul {
  margin: 6px 72px 23px;
}

.form_input ul li {
  border-bottom: 1px solid #d0d0d0;
  height: 50px;
  display: flex;
  align-items: center;
}

.form_input ul li label {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #222222;
}

.form_input ul li input {
  border: none;
  outline: none;
  font-size: 16px;
  color: #222;
  display: block;
  height: 30px;
}

.form_input p.btn {
  cursor: pointer;
  width: 263px;
  height: 53px;
  background: #254ed4;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
  line-height: 53px;
  font-size: 16px;
  color: #fffefe;
}

/* 培训计划弹框 */
.plan_box {
  /* position: relative; */
}

.plan_box .form_input ul {
  margin-top: 29px;
}

.form_box .banner_box {
  width: 100%;
  margin-top: -87px;
}

.form_box .banner_box img {
  width: 100%;
}

.form_box .banner_box p.close {
  position: absolute;
  right: 0;
  top: 5px;
  width: 22px;
  height: 22px;
}

.plan_box div.title {
  text-align: center;
  margin-top: 28px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #222222;
}
.plan_box p.little_title {
  font-size: 14px;
  color: #999;
  margin: 15px 50px 0;
  text-align: left;
}
.form_box .banner_box p.close img {
  width: 100%;
  height: 100%;
}

.plan_box .form_input p.btn {
  margin-top: 64px;
}

/* 右侧固定按钮 */
.fixed_right {
  position: fixed;
  top: 70%;
  right: 0%;
  margin-left: 600px;
  transform: translate(-50%, 0%);
  width: 93px;
  // height: 223px;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 7px 1px rgba(20, 6, 5, 0.16);
  border-radius: 6px;
  z-index: 12;
  /* overflow: hidden; */
}

.fixed_right ul {
  padding-top: 32px;
}

.fixed_right li.line {
  width: 78px;
  height: 1px;
  background: #d0d0d0;
  margin: 17px auto;
}

.fixed_right li p {
  cursor: pointer;
}

.fixed_right li p.img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.fixed_right li p.img img {
  width: 100%;
  height: 100%;
}

.fixed_right li p.text {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #254ed4;
  text-align: center;
  margin-top: 15px;
}

li.show_more:hover .show_text {
  display: block;
}

.show_text {
  width: 313px;
  height: 206px;
  background: #ffffff;
  box-shadow: 0px 4px 7px 1px rgba(20, 6, 5, 0.16);
  border-radius: 4px;
  position: absolute;
  right: 95px;
  top: 0;
  z-index: 13px;
  display: none;
}

.show_text .title_icon {
  display: flex;
  align-items: center;
  padding: 21px;
}

.show_text .title_icon span {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #254ed4;
  margin-left: 10px;
}

.phone_list {
  background: #e5eaef;
  display: flex;
  min-height: 132px;
}
.left_text {
  padding-top: 12px;
  margin-left: 20px;  
  .num2{
    display: flex;
    p{
      cursor: auto;
    }
  }
}
.phone_list p.text1 {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #222222;
  margin-bottom: 11px;
}
.phone_list div.num {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #254ed4;
  margin-bottom: 5px;
}
.phone_list div.num span {
  color: #222;
  margin-right: 10px;
}
.code_img {
  width: 81px;
  height: 81px;
}
.code_img img {
  width: 100%;
  height: 100%;
  margin-left: 22px;
  // margin-top: 31px;
}
/* 左侧固定栏 */
.fixed_left {
  width: 181px;
  height: 475px;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url("~@/assets/mask_box/left_img.png") no-repeat;
  background-size: 100% 100%;
  z-index: 100;
}
.fixed_left p.tips {
  position: absolute;
  right: 27%;
  top: 33%;
  writing-mode: vertical-rl;
  font-size: 20px;
  font-family: jiangchengzhengyiti600W;
  font-weight: bold;
  color: #254ed4;
}
.fixed_left p.btn {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  width: 117px;
  height: 117px;
  background: #254ed4;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

// 劳动关系学院-领取证书弹框
.ldxy_mask_box{
  .cont_data,
  .msg_cont_info,
  .question_cont_box{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .head_title{
      background: url('~@/assets/pc/pop/text_bgm.png') no-repeat;
      background-size: 100% 100%;
      height: 100px;
      position: relative;
      p.close{
        position: absolute;
        right: 20px;
        top:20px;
        img{
          width: 24px;
          cursor: pointer;
        }
      }
      p.text{
        text-align: center;
        font-weight: 500;
        font-size: 32px;
        color: #2345CF;
        line-height: 32px;
        letter-spacing: 5px;
        line-height: 100px;
      }
    }
  }
  .cont_data{
    width: 800px;
    height: 429px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    .cont_info{
      width: 500px;
      height: 135px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(218,233,252,0.67);
      border-radius: 8px 8px 8px 8px;
      margin: 50px auto;
      .learn_time{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 32px;
        p.text{
          font-size: 18px;
          color: #3D3D3D;
        }
        p.num{
          color: #1F52D3;
          font-size: 24px;
          font-weight: bold;
          span{
            font-size: 18px;
          }
        } 
      }
      .tips_text{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        span{
          background: rgba(151, 244, 182, .2);
          color: #0BB828;
          font-size: 14px;
          line-height: 26px;
          padding: 0 27px;
          border-radius: 24px;
        }
      }
      .state2{
        span{
          background:rgba(255, 199, 199, .2) ;
          color: #EA0000;
        }
      }
    }
    .get_btn{
      width: 156px;
      height: 44px;
      background: #1F52D3;
      border-radius: 206px 206px 206px 206px;
      margin: 54px auto 0;
      text-align: center;
      line-height: 44px;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
    }
    .disable{
      background: #B9B9B9;
    }
  }

  .msg_cont_info{
    width: 452px;
    height: 356px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    p.close{
      display: flex;
      justify-content: flex-end;
      img{
        width: 24px;
        height: 24px;
        margin: 20px 20px 0 0;
      }
    }
    .true_icon{
      width: 76px;
      height: 76px;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
    .text_info{
      margin-top: 25px;
      p.text{
        font-weight: 400;
        font-size: 18px;
        color: #3D3D3D;
        line-height: 35px;
        text-align: center;
        span{
          color: #1F52D3;
        }
      }
    }
    .btn_sure{
      margin: 45px auto ;
      width: 156px;
      height: 44px;
      background: #1F52D3;
      border-radius: 206px 206px 206px 206px;
      text-align: center;
      line-height: 44px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }
  .question_cont_box{
    width: 800px;
    height: 90vh;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    .quest_cont_info{
      height: calc(90vh - 100px);
      overflow-y: auto;
      padding: 0 30px;
      box-sizing: border-box;
      .intro_text_info{
        margin-top: 20px;
        p{
          text-align: center;
          font-size: 16px;
        }
      }
      ul{
        .tips_text{ 
          font-weight: bold;
          margin-top: 30px;
          font-size: 18px;
        }
        div.li_item{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
         
        }
        li{
          margin-top: 20px;
          width: 100%;
          p.title_text{
            font-size: 16px;
            span{
              color: red;
            }
          }
          p.input{
            width: 100%;
            border: 1px solid #D9D9D9;
            height: 40px;
            border-radius: 4px;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            input{
              width: 96%;
              height: 96%;
              font-size: 16px;
            }
            .el-select{
              width: 96%;
            }
            ::v-deep .el-input__inner{
              border: none;
            }
            .custom-placeholder ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              font-size: 16px; /* 设置您想要的字体大小 */
              color: grey; /* 设置您想要的颜色 */
            }
            .custom-placeholder :-ms-input-placeholder { /* Internet Explorer 10-11 */
              font-size: 16px;
            }
            .custom-placeholder ::-ms-input-placeholder { /* Microsoft Edge */
              font-size: 16px;
            }
            .custom-placeholder ::placeholder {
              font-size: 16px;
            }
          }
          p.textarea{
            width: 100%;
            height: 80px;
            border: 1px solid #D9D9D9;
            border-radius: 4px;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            textarea{
              width: 96%;
              height: 96%;
              font-size: 16px;
              resize: none;
            }
          }
          input::placeholder {
                font-size: 16px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              -webkit-appearance: none;
          }

          input[type="number"] {
                -moz-appearance: textfield;
          }

        }
      }
      .submit_btn{
        margin: 45px auto ;
        width: 156px;
        height: 44px;
        background: #1F52D3;
        border-radius: 206px 206px 206px 206px;
        text-align: center;
        line-height: 44px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>